.react-bootstrap-table table {
  table-layout: auto !important;
}

.beertable-image {
  height: 40px;
  width: 40px;
  border: 1px solid #343a40;
  margin: 5px 10px 5px 5px;
}

.progress-bar-gold {
  background-color: rgb(255, 193, 7) !important;
}

.progress-half-size {
  height: 0.5rem;
}

.react-bootstrap-table > table > tbody > tr {
  cursor: pointer;
}

.react-bootstrap-table > table > tbody > .expanding-row {
  cursor: inherit;
}

.table-profile-picture {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin: 4px;
  margin-right: 10px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
}

.table-profile-picture-empty {
  width: 40px;
  height: 40px;
  margin: 4px;
  margin-right: 10px;
}

.table td {
  vertical-align: middle;
}

.table-flex {
  display: flex;
  align-items: center;
  text-align: left;
  vertical-align: middle;
}

.table-twoline-main {
  display: block;
  white-space: nowrap;
  font-weight: 600;
}

.table-twoline-main-count {
  text-align: center;
}

.table-twoline-sub {
  display: block;
  white-space: nowrap;
  font-size: 12px;
}

.table-twoline-sub-count {
  text-align: center;
}

.table-count-container {
  width: 100px;
}
