.tasting-table * td {
  text-align: center;
  vertical-align: middle;
}

.tasting-table * th {
  text-align: center;
  vertical-align: middle;
}

.tasting-checkin {
  font-size: 3em;
  font-weight: 700;
}

.tasting-heading {
  margin: 10px auto;
  margin-bottom: 30px;
  text-align: center;
}

.missing-token {
  color: red;
}
