.attribution {
  display: block;
  margin: 10px auto;
}

.attribution-image {
  display: block;
  max-width: 160px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;
}

.attribution-text {
  color: #555555;
  font-size: 12px;
  display: block;
  text-align: center;
}
