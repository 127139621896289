.profile {
  border-radius: 10px;
  box-shadow: 0 8px 40px rgba(0, 0, 0, 0.2);
  min-height: 90px;
}

.profile-data {
  overflow: auto;
  display: flex;
  align-items: center;
}

.profile-name {
  color: #fff;
  display: block;
  font-size: 25px;
  font-weight: 700;
}

.profile-extra {
  color: #aaa;
  display: block;
  font-size: 12px;
  font-weight: 300;
}

.profile-extra-count {
  font-weight: 600;
  font-size: 14px;
  margin-right: 2px;
}

.profile-extra-error {
  font-size: 18px;
}

.profile-pic {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin: 20px;
  float: left;
  display: block;
  box-shadow: 0 13px 26px rgba(0, 0, 0, 0.2), 0 3px 6px rgba(0, 0, 0, 0.2);
}

.update-button {
  padding: 10px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  height: 100%;
}

.update-progress {
  width: 100%;
  background-color: #ff000000;
}

.update-text {
  color: #aaa;
  display: block;
  margin-top: 5px;
  font-size: 12px;
  font-weight: 300;
}

.user-not-exist {
  margin-top: 20px;
  margin-bottom: 20px;
  letter-spacing: 1px;
  font-weight: 500;
  font-size: 20px;
  text-align: center;
}

.user-tabs {
  margin-top: 20px;
  margin-bottom: 20px;
  letter-spacing: 1px;
  font-weight: 500;
  font-size: 13px;
}

.user-nav-tab {
  margin-right: 5px;
  margin-left: 5px;
}

.user-nav-tab:hover {
  text-decoration: none;
}

.user-nav-link {
  color: #000 !important;
}

.user-nav-link:hover {
  color: #000 !important;
  background-color: #e7edf3 !important;
}

.active .user-nav-link {
  color: #fff !important;
  background-color: #343a40 !important;
}

.active .user-nav-link:hover {
  color: #fff !important;
  background-color: #4e545a !important;
}
